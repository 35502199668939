<template>
    <el-button type="primary" size="small" icon="MagicStick" @click="this.edit = true">导入</el-button>
    <el-dialog v-model="edit" :title="dialogTitle" width="85%" :before-close="handleClose" align-center>
        <el-upload class="upload-demo logo" drag action="" multiple :on-change="handleChange" :auto-upload="false"
            :show-file-list="false">
            <el-icon class="el-icon--upload ">
                <upload-filled />
            </el-icon>
            <view class="el-upload__text logo"> 拖拽文件到此处 <em>上传</em> </view>
        </el-upload>
        <el-card class="box-card-t" shadow="never" v-loading="loading">
            <el-table :data="fileList" stripe max-height="300" size="small">
                <el-table-column v-for="(item, index) in tableHead" :key="index" :label="item.label"
                    :prop="item.property" show-overflow-tooltip>
                </el-table-column>
            </el-table>
        </el-card>
        <template #footer>
            <span class="dialog-footer">
                <el-button @click="this.edit = false">取消</el-button>
                <el-button type="danger" @click="emptyAll()">
                    清空
                </el-button>
                <el-button type="warning" @click="updatas(true)" v-if="modifyData">更新</el-button>
                <el-button type="primary" @click="updatas(false)">
                    上传
                </el-button>
            </span>
        </template>
    </el-dialog>
</template>

<script>
    //导入文件
    import axios from "@/utils/request";
    import {
        importExcel
    } from '@/utils/importExcel'
    import {
        ElMessage,
        ElLoading,
    } from 'element-plus'
    export default {
        name: 'CatUpload',
        props: {
            tabsName: String,
            tableHead: Array,
            modifyData: Boolean
        },
        created() {
            console.log(this.tableHead)
        },
        computed: {},
        data() {
            return {
                edit: false,
                loading: false,
                dialogTitle: '批量处理数据',
                fileList: [], //表数据
                data: {
                    tabname: "",
                    data: [],
                    modifyData: false, //是否修改
                },
            }
        },
        methods: {
            updatas(v) {
                this.data.modifyData = v
                this.loading = true
                this.data.tabname = this.tabsName
                this.data.data = this.fileList
                axios({
                    method: "post",
                    url: 'updata',
                    data: this.data,
                }).then(res => {
                    this.loading = false
                    // this.fileList = res.datamag
                    if (res.hasOwnProperty("add")) {
                        ElMessage({
                            type: "success",
                            message: `成功添加${res.add}行数据，成功更新${res.update}行数据`,
                        })
                        this.data.modifyData = false
                    }
                })
            },
            handleClose(v) {
                // 关闭页面
                this.edit = false
            },
            emptyAll() {
                // 清空
                this.fileList.length = 0
            },
            handleChange(file) { // 读入Excel文件内容： 核心部分代码(handleChange 和 importfile)
                ElMessage({
                    type: "warning",
                    message: "正在读取",
                })
                this.loading = true
                const fileName = file.name;
                const fileType = fileName.substring(fileName.lastIndexOf(".") + 1);
                if (fileName.indexOf(this.tabsName) == -1) {
                    ElMessage({
                        type: "warning",
                        message: "请上传" + this.tabsName + '表',
                    })
                    this.loading = false
                    return
                }
                if (!file.raw) {
                    ElMessage({
                        type: "warning",
                        message: "请上传附件！",
                    })
                    this.loading = false
                    return
                }
                if (fileType !== "xlsx" && fileType !== "xls") {
                    ElMessage({
                        type: "warning",
                        message: "附件格式错误，仅支持xlsx，请重新上传！",
                    })
                    this.loading = false
                    return
                }
                importExcel(file.raw, {
                    tableHead: this.tableHead
                }, this.data.tabname).then(v => {
                    console.log(v)
                    this.fileList = this.fileList.concat(v.correct)
                    this.loading = false
                })
            }
        }
    }
</script>

<style scoped>
    .box-card-t {
        margin-top: 16px;
    }

    .card-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
</style>